import React, { Component } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import clsx from 'clsx'

import Logo from '../../images/logo-centered.svg'

import './footer.scss'

class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = { value: '', disabled: 'disabled' }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleFooterLink = this.handleFooterLink.bind(this)
  }

  handleFooterLink = (e, type) => {
    e.preventDefault()
    this.props.toggleModal(type)
  }
  handleSubmit = (e) => {
    e.preventDefault()
    this.props.submitZip(this.state.value)
    this.props.nextStep('zip')
    navigate('/apply/birth')
  }
  handleChange(event) {
    const isDisabled =
      event.target.value !== '' && event.target.value.length === 5 ? '' : 'disabled'
    this.setState({ value: event.target.value, disabled: isDisabled })
  }

  render = () => {
    const { phone, captive } = this.props
    let { hideCta, footerAlt } = this.props
    const { disabled, value } = this.state

    if (captive.newApply) {
      hideCta = true
      footerAlt = true
    }

    const footerClass = footerAlt ? 'alt' : ''

    return (
      <footer id="footer" className={clsx(footerClass)}>
        <div className="container">
          {!hideCta && !footerAlt && (
            <div className="cta-holder">
              <span className="text">
                Speak with a licensed <br />
                insurance agent! <br />
              </span>
              <a
                href={`tel:${phone}`}
                className={clsx('btn with-icon', this.props.TTYBig && 'TTYBig')}
              >
                <span className="icon-phone" /> <span className="text">{phone}</span>
              </a>
            </div>
          )}
          {footerAlt && !hideCta && (
            <div className={clsx(!this.props.hideOnline && 'cta-new', 'mb5')}>
              {!this.props.hideOnline && (
                <div className="w-50 w-100-s t-center mb4-m">
                  <h3 className="t-bold pb2">Compare medicare plans online</h3>
                  <div className="inline">
                    <form action="#" onSubmit={this.handleSubmit} noValidate="novalidate">
                      <input
                        className="mr4 mr3-m"
                        id="zip"
                        placeholder="Zip Code"
                        maxLength="5"
                        type="text"
                        data-required="true"
                        value={value}
                        onChange={this.handleChange}
                      />
                      <button
                        type="submit"
                        disabled={disabled}
                        className={`btn` + (disabled ? ` disabled` : ``)}
                      >
                        Start my quote
                      </button>
                    </form>
                  </div>
                </div>
              )}
              <div
                className={clsx(!this.props.hideOnline && 'w-50', 'w-100-s', 't-center')}
              >
                <h3 className="t-bold pb2">Speak to a licensed agent</h3>
                <a href={`tel:${phone}`} className="btn with-icon btn-large">
                  <span className="icon-phone" /> <span className="text">{phone}</span>
                </a>
              </div>
            </div>
          )}
          {footerAlt && (
            <div className="logo t-center mb4">
              <img src={Logo} alt="Medicare Companion" />
            </div>
          )}
          <div className="menu-holder">
            <ul className="footer-menu">
              <li>
                <span className="copyright">
                  &copy; 2020 <Link to="/">MedicareCompanion</Link>
                </span>
              </li>
              <li>
                <Link onClick={(e) => this.handleFooterLink(e, 'terms')} to="/">
                  Terms of Services
                </Link>
              </li>
              <li>
                <Link onClick={(e) => this.handleFooterLink(e, 'privacy')} to="/">
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-cege">
          <p>A privately owned, non-government website powered by Cege Media.</p>
        </div>
      </footer>
    )
  }
}

Footer.propTypes = {
  phone: PropTypes.string,
  hideCta: PropTypes.bool,
  footerAlt: PropTypes.bool,
  hideOnline: PropTypes.bool,
  TTYBig: PropTypes.bool,
}

Footer.defaultProps = {
  phone: ``,
  hideCta: false,
  footerAlt: false,
  hideOnline: false,
  TTYBig: false,
}

export default Footer
