import React from 'react'
import clsx from 'clsx'
import { connect } from 'react-redux'
import { navigate } from 'gatsby-link'
import moment from 'moment'

import {
  toggleField,
  submitData,
  toggleBypassZip,
  nextStep,
} from '../../reducers/captive'

import CountDown from './CountDown'

import styles from './styles.module.scss'

const startTime = '10-15-2020'
const endTime = '12-07-2020'

const CountDownOpenEnrollment = ({ phone, toggleBypassZip, nextStep }) => {
  const eventstartTime = moment(endTime, 'MM-DD-YYYY HH:mm:ss').unix()
  const currentTime = moment().unix()
  const diffTime = eventstartTime - currentTime

  if (diffTime < 10) {
    return null
  }

  const eventendTime = moment(startTime, 'MM-DD-YYYY HH:mm:ss').unix()
  const diffendTime = eventendTime - currentTime
  const useEndTime = diffendTime < 10

  const navigateToFlow = () => {
    toggleBypassZip(true)
    nextStep('zip')
    navigate('/apply/dobyearNew')
  }

  return (
    <div className={styles.countDownWrapper}>
      <div className="container">
        <div className={styles.countDownContainer}>
          <div className={styles.left}>
            <div className={styles.openEnrollmentIn}>
              Open Enrollment {useEndTime ? 'ends in' : 'in'}
            </div>
            <div className={styles.call}>
              Call Now{' '}
              <a href={`tel:${phone}`} className="clear-tty">
                {phone}
              </a>
            </div>
          </div>
          <div>
            <CountDown endTime={useEndTime ? endTime : startTime} />
          </div>
          <button
            onClick={navigateToFlow}
            type="button"
            className={clsx('btn', styles.button)}
          >
            Get Quote Now
          </button>
        </div>
      </div>
    </div>
  )
}

export default connect(null, { toggleField, submitData, toggleBypassZip, nextStep })(
  CountDownOpenEnrollment,
)
