import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Footer from '../footer'
import Modal from '../modal'

import CountDownOpenEnrollment from '../CountDownOpenEnrollment'

import '../../scss/main.scss'

class Layout extends Component {
  constructor(props) {
    super(props)
    if (props.includeCitySearch) {
      props.checkUserCity()
    }
  }

  componentDidMount = () => {
    this.props.checkPrePopulatedData(this.props.location.search)
    this.props.checkUrlSrc(this.props.location.search)
    this.props.checkUrlCampaign(this.props.location.search)
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.captive.campaign !== prevProps.captive.campaign) {
      if (window.dataLayer) {
        window.dataLayer.push({
          utm_campaign: this.props.captive.campaign,
        })
      }
    }
    if (this.props.captive.src !== prevProps.captive.src) {
      if (window.dataLayer) {
        window.dataLayer.push({
          src: this.props.captive.src,
        })
      }
    }
  }

  render() {
    const {
      children,
      phone,
      hideFooter,
      hideCta,
      footerAlt,
      hideOnline,
      showCountDown,
    } = this.props
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={(data) => (
          <div id="wrapper" className={this.props.newFont ? 'new-font' : ''}>
            {showCountDown && <CountDownOpenEnrollment phone={phone} />}
            <a className="accessibility" href="#main">
              Skip to Content
            </a>
            {children}
            {!hideFooter && (
              <Footer
                phone={phone}
                hideCta={hideCta}
                footerAlt={footerAlt}
                hideOnline={hideOnline}
                TTYBig={this.props.TTYBig}
              />
            )}
            <a className="accessibility" href="#wrapper">
              Back to top
            </a>
            <Modal type="terms" />
            <Modal type="privacy" />
          </div>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  phone: PropTypes.string,
  hideFooter: PropTypes.bool,
  hideCta: PropTypes.bool,
  footerAlt: PropTypes.bool,
  includeCitySearch: PropTypes.bool,
  hideOnline: PropTypes.bool,
  newFont: PropTypes.bool,
  TTYBig: PropTypes.bool,
}

Layout.defaultProps = {
  phone: ``,
  hideFooter: false,
  hideCta: false,
  footerAlt: false,
  includeCitySearch: false,
  hideOnline: false,
  newFont: false,
  TTYBig: false,
  showCountDown: false,
}

export default Layout
