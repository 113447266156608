import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import get from 'lodash/get'
import uniq from 'lodash/uniq'

import './partners.scss'

const Partners = () => {
  const data = useStaticQuery(graphql`
    query GetTCPAMarketingPartners {
      allContentfulTcpaMarketingPartners {
        nodes {
          companionMarketingPartnersList {
            all
          }
        }
      }
    }
  `)

  const marketingPartnersList = get(
    data,
    'allContentfulTcpaMarketingPartners.nodes[0].companionMarketingPartnersList.all',
    [],
  )

  const orderedMarketingPartnersList = uniq(marketingPartnersList).sort()

  return (
    <div className="partners">
      <div className="section-heading">
        <h2>List of Insurance Companies &amp; Agencies That May Be Contacting You</h2>
      </div>
      <div className="text-holder with-decor">
        {orderedMarketingPartnersList.map((partner) => {
          if (partner?.toLowerCase()?.includes('everquote')) {
            return (
              <span>
                <a
                  href="https://plist.everquote.com/partners.html"
                  target="__blank"
                  rel="nofollow noreferrer noopener"
                >
                  Everquote Inc
                </a>
              </span>
            )
          }
          return <span key={partner}>{partner}</span>
        })}
      </div>
      <br />
      <a href="/partners" target="__blank">
        Click Here To View All
      </a>
    </div>
  )
}

export default Partners
