import React, { useState, useEffect } from 'react'
import moment from 'moment'

import styles from './styles.module.scss'

const CountDown = ({ endTime }) => {
  const [state, setState] = useState({
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00',
  })

  useEffect(() => {
    const eventEndTime = moment(endTime, 'MM-DD-YYYY HH:mm:ss').unix()
    const currentTime = moment().unix()
    const diffTime = eventEndTime - currentTime
    let duration = moment.duration(diffTime * 1000, 'milliseconds')
    const interval = 1000

    const timer = setInterval(() => {
      duration = moment.duration(duration.asMilliseconds() - interval, 'milliseconds')
      setState({
        days: Math.floor(moment.duration(duration).asDays()).toString(),
        hours: moment.duration(duration).hours().toString(),
        minutes: moment.duration(duration).minutes().toString(),
        seconds: moment.duration(duration).seconds().toString(),
      })
    }, interval)

    let currentTimer = timer

    return function cleanup() {
      clearInterval(currentTimer)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const time = ['Days', 'Hours', 'Minutes', 'Seconds']
  return (
    <div className={styles.countDownWrapper}>
      <div className={styles.countdown}>
        {time.map((value, index) => (
          <div key={index} className={styles.countItem}>
            <span className={styles.countValue}>
              {state[value.toLowerCase()].length === 1 ? (
                <span>
                  <span className={styles.individualItem}>0</span>
                  <span className={styles.individualItem}>
                    {state[value.toLowerCase()]}
                  </span>
                </span>
              ) : (
                <span>
                  {state[value.toLowerCase()].split('').map((x, i) => (
                    <span key={i} className={styles.individualItem}>
                      {x}
                    </span>
                  ))}
                </span>
              )}
            </span>
            <span className={styles.countName}>{value}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

CountDown.defaultProps = {
  endTime: '10-15-2020',
}

export default CountDown
